/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountResponse } from '../model';
// @ts-ignore
import { CurrentFacebookPageResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookAccountsWithInstagramResponse } from '../model';
// @ts-ignore
import { FacebookLeadgenFormsResponse } from '../model';
// @ts-ignore
import { FacebookPixelIdsResponse } from '../model';
// @ts-ignore
import { FacebookPromotePageResponse } from '../model';
/**
 * FacebookAccountGMApi - axios parameter creator
 * @export
 */
export const FacebookAccountGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current account of company facebook integration
         * @summary Get current account of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCurrentFacebookAccount: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyCurrentFacebookAccount', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account/current_account`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company facebook account details
         * @summary Get company facebook account details
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookAccount: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFacebookAccount', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company facebook accounts with instagram accounts
         * @summary Get company facebook accounts with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookAccounts: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFacebookAccounts', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account/accounts`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get pixel ids of company facebook integration
         * @summary Get pixel ids of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookPixelIds: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFacebookPixelIds', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account/pixel_ids`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company facebook promote pages with instagram accounts
         * @summary Get company facebook promote pages with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookPromotePages: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFacebookPromotePages', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account/promote_pages`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company lead generation forms
         * @summary Get company lead generation forms
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLeadGenForms: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyLeadGenForms', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook_account/leadgen_forms`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookAccountGMApi - functional programming interface
 * @export
 */
export const FacebookAccountGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookAccountGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current account of company facebook integration
         * @summary Get current account of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyCurrentFacebookAccount(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentFacebookPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyCurrentFacebookAccount(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company facebook account details
         * @summary Get company facebook account details
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFacebookAccount(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFacebookAccount(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company facebook accounts with instagram accounts
         * @summary Get company facebook accounts with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFacebookAccounts(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAccountsWithInstagramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFacebookAccounts(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get pixel ids of company facebook integration
         * @summary Get pixel ids of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFacebookPixelIds(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookPixelIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFacebookPixelIds(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company facebook promote pages with instagram accounts
         * @summary Get company facebook promote pages with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFacebookPromotePages(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookPromotePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFacebookPromotePages(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company lead generation forms
         * @summary Get company lead generation forms
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLeadGenForms(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookLeadgenFormsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLeadGenForms(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacebookAccountGMApi - factory interface
 * @export
 */
export const FacebookAccountGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookAccountGMApiFp(configuration)
    return {
        /**
         * Get current account of company facebook integration
         * @summary Get current account of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCurrentFacebookAccount(companyId: string, options?: any): AxiosPromise<CurrentFacebookPageResponse> {
            return localVarFp.getCompanyCurrentFacebookAccount(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company facebook account details
         * @summary Get company facebook account details
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookAccount(companyId: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.getCompanyFacebookAccount(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company facebook accounts with instagram accounts
         * @summary Get company facebook accounts with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookAccounts(companyId: string, options?: any): AxiosPromise<FacebookAccountsWithInstagramResponse> {
            return localVarFp.getCompanyFacebookAccounts(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get pixel ids of company facebook integration
         * @summary Get pixel ids of company facebook integration
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookPixelIds(companyId: string, options?: any): AxiosPromise<FacebookPixelIdsResponse> {
            return localVarFp.getCompanyFacebookPixelIds(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company facebook promote pages with instagram accounts
         * @summary Get company facebook promote pages with instagram accounts
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFacebookPromotePages(companyId: string, options?: any): AxiosPromise<FacebookPromotePageResponse> {
            return localVarFp.getCompanyFacebookPromotePages(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company lead generation forms
         * @summary Get company lead generation forms
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLeadGenForms(companyId: string, options?: any): AxiosPromise<FacebookLeadgenFormsResponse> {
            return localVarFp.getCompanyLeadGenForms(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookAccountGMApi - interface
 * @export
 * @interface FacebookAccountGMApi
 */
export interface FacebookAccountGMApiInterface {
    /**
     * Get current account of company facebook integration
     * @summary Get current account of company facebook integration
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyCurrentFacebookAccount(companyId: string, options?: any): AxiosPromise<CurrentFacebookPageResponse>;

    /**
     * Get company facebook account details
     * @summary Get company facebook account details
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyFacebookAccount(companyId: string, options?: any): AxiosPromise<AccountResponse>;

    /**
     * Get company facebook accounts with instagram accounts
     * @summary Get company facebook accounts with instagram accounts
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyFacebookAccounts(companyId: string, options?: any): AxiosPromise<FacebookAccountsWithInstagramResponse>;

    /**
     * Get pixel ids of company facebook integration
     * @summary Get pixel ids of company facebook integration
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyFacebookPixelIds(companyId: string, options?: any): AxiosPromise<FacebookPixelIdsResponse>;

    /**
     * Get company facebook promote pages with instagram accounts
     * @summary Get company facebook promote pages with instagram accounts
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyFacebookPromotePages(companyId: string, options?: any): AxiosPromise<FacebookPromotePageResponse>;

    /**
     * Get company lead generation forms
     * @summary Get company lead generation forms
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApiInterface
     */
    getCompanyLeadGenForms(companyId: string, options?: any): AxiosPromise<FacebookLeadgenFormsResponse>;

}

/**
 * FacebookAccountGMApi - object-oriented interface
 * @export
 * @class FacebookAccountGMApi
 * @extends {BaseAPI}
 */
export class FacebookAccountGMApi extends BaseAPI implements FacebookAccountGMApiInterface {
    /**
     * Get current account of company facebook integration
     * @summary Get current account of company facebook integration
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyCurrentFacebookAccount(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyCurrentFacebookAccount(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company facebook account details
     * @summary Get company facebook account details
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyFacebookAccount(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyFacebookAccount(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company facebook accounts with instagram accounts
     * @summary Get company facebook accounts with instagram accounts
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyFacebookAccounts(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyFacebookAccounts(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get pixel ids of company facebook integration
     * @summary Get pixel ids of company facebook integration
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyFacebookPixelIds(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyFacebookPixelIds(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company facebook promote pages with instagram accounts
     * @summary Get company facebook promote pages with instagram accounts
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyFacebookPromotePages(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyFacebookPromotePages(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company lead generation forms
     * @summary Get company lead generation forms
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountGMApi
     */
    public getCompanyLeadGenForms(companyId: string, options?: any) {
        return FacebookAccountGMApiFp(this.configuration).getCompanyLeadGenForms(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}
