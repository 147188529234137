import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import { useLocale } from '@app/hooks';
import { OBJECTIVES } from '@app/constants';

const DynamicLocationAdsObjectivesInput: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ObjectivesForm',
  };
  const { t } = useLocale();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const OBJECTIVE_OPTIONS = [
    {
      label: t(`form.call.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.CALL_NOW,
    },
    {
      label: t(`form.finalUrl.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.FINAL_URL,
    },
    {
      label: t(`form.instagramVisit.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.INSTAGRAM_VISIT,
    },
    {
      label: t(`form.iosApp.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.IOS_APP_INSTALLS,
    },
    {
      label: t(`form.androidApp.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.ANDROID_APP_INSTALLS,
    },
    {
      label: t(`form.outcomeAwareness.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.OUTCOME_AWARENESS,
    },
    {
      label: t(`form.outcomeSales.label`, SCOPE_OPTIONS),
      value: OBJECTIVES.OUTCOME_SALES,
    },
  ];

  return (
    <Box>
      <Form.Select
        name="allowedObjective"
        label={t(`form.label`, SCOPE_OPTIONS)}
        rules={{
          required: { value: true, message: t('form.errors.required', SCOPE_OPTIONS) },
        }}
        control={control}
        options={OBJECTIVE_OPTIONS}
        error={errors.allowedObjective}
      />
    </Box>
  );
};

export default DynamicLocationAdsObjectivesInput;
