const OBJECTIVES = Object.freeze({
  CALL_NOW: 'CALL_NOW',
  FINAL_URL: 'FINAL_URL',
  WHATSAPP_MESSAGE: 'WHATSAPP_MESSAGE',
  MESSENGER: 'MESSENGER',
  INSTAGRAM_VISIT: 'INSTAGRAM_VISIT',
  LEAD_GENERATION: 'LEAD_GENERATION',
  ANDROID_APP_INSTALLS: 'ANDROID_APP_INSTALLS',
  IOS_APP_INSTALLS: 'IOS_APP_INSTALLS',
  OUTCOME_AWARENESS: 'OUTCOME_AWARENESS',
  OUTCOME_SALES: 'OUTCOME_SALES',
});

export default OBJECTIVES;
